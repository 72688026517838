import { Badge, Button, Layout, LegacyCard, Text } from "@shopify/polaris";
import { IS_BROWSER, adminRoutePrefix, shopUrlPropertyName } from "@smartrr/shared/constants";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { nowUTCLuxon } from "@smartrr/shared/utils/dateUtils";
import React, { useCallback, useEffect, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { switchToAccountPlan } from "@vendor-app/app/_state/actionCreators/accountPlans";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
interface Props {
  planName: string;
  planFee: number;
  planIcon: string;
  isPlanFeeFixed: boolean;
  percentageFee: number;
  trialDays: number;
  featuresDescription: string[];
  integrationsDescription: string[];
  isCurrent: boolean;
  isLocked: boolean;
  isPending: boolean;
  pendingConfirmationUrl: string | undefined;
  setRedirectToShopify: React.Dispatch<React.SetStateAction<boolean>>;
  hasActivePlan: boolean;
  trialPeriodEnd: ISODateString;
  activePlanFee?: number | null;
  setIsDownGrading: React.Dispatch<React.SetStateAction<boolean>>;
  confirmDowngrading: boolean;
  downgradePlan: string;
  setDowngradePlan: React.Dispatch<React.SetStateAction<string>>;
}

export function AccountPlan({
  planName,
  planIcon,
  planFee,
  trialDays,
  featuresDescription,
  integrationsDescription,
  isCurrent,
  isLocked,
  isPending,
  pendingConfirmationUrl,
  setRedirectToShopify,
  hasActivePlan,
  trialPeriodEnd,
  isPlanFeeFixed,
  percentageFee,
  activePlanFee,
  setIsDownGrading,
  confirmDowngrading,
  downgradePlan,
  setDowngradePlan,
}: Props): JSX.Element {
  const [redirectOnApprove, setRedirectOnApprove] = useState(false);
  const [isSwitchingPlan, setIsSwitchingPlan] = useState(false);
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const trialBadgeVisible = trialPeriodEnd && ISODateString.fromString(trialPeriodEnd) > nowUTCLuxon();

  const onSwitchToPlan = () => {
    if (activePlanFee && planFee < activePlanFee) {
      setDowngradePlan(planName);
      setIsDownGrading(true);
    } else {
      onSwitchToPlanConfirm();
    }
  };

  const onSwitchToPlanConfirm = useCallback(async () => {
    setIsSwitchingPlan(true);
    const returnUrl = getReturnUrl(hasActivePlan);
    await dispatch(switchToAccountPlan(planName, returnUrl, addToast));

    setRedirectOnApprove(true);
    setIsSwitchingPlan(false);
  }, [planName, addToast]);

  useEffect(() => {
    if (IS_BROWSER && pendingConfirmationUrl && redirectOnApprove) {
      setRedirectToShopify(true);
      // Redirect needs happen in a new tab, because redirect inside iframe just gets in an infinite loop
      window.open(pendingConfirmationUrl!, "_blank");
    }
  }, [pendingConfirmationUrl, redirectOnApprove]);

  useEffect(() => {
    confirmDowngrading && downgradePlan == planName && onSwitchToPlanConfirm();
  }, [confirmDowngrading, onSwitchToPlanConfirm, downgradePlan, planName]);
  return (
    <div className="account-plan__card">
      <Layout.Section oneThird>
        <LegacyCard
          sectioned
          title={
            <React.Fragment>
              <div className="account-plan__name">
                <React.Fragment>
                  <Text variant="headingMd" as="h2">
                    {planIcon} {planName}
                  </Text>
                  <span className="account-plan__current-badge">
                    {!!isCurrent && (
                      <Badge status="success">{trialBadgeVisible ? "Free trial" : "Current plan"}</Badge>
                    )}
                  </span>
                </React.Fragment>
              </div>
              <div className="account-plan__plan-fee">
                {`${isPlanFeeFixed ? "$" : "starting at $"}${planFee}/month\n+ ${
                  percentageFee * 100
                }% subscriber GMV`}
              </div>
            </React.Fragment>
          }
        >
          <div className="account-plan__card-body">
            <p className="account-plan__body__features-header">FEATURES</p>
            <ul className="account-plan__features-list">
              {featuresDescription.map((feature, index) =>
                feature.trim() ? <li key={index}>{feature}</li> : <span key={index}>{feature}</span>
              )}
            </ul>
            {integrationsDescription.length > 0 && (
              <React.Fragment>
                <p className="account-plan__body__integrations-header">INTEGRATIONS</p>
                <ul className="account-plan__features-list">
                  {integrationsDescription.map((integration, index) => (
                    <li key={index}>{integration}</li>
                  ))}
                </ul>
              </React.Fragment>
            )}
          </div>
          <div className="account-plan__card-footer">
            <React.Fragment>
              {!!isPending && !!pendingConfirmationUrl && (
                <div className="account-plan__card-footer__approve-btn">
                  {isSafari ? (
                    <a
                      target="_blank"
                      href={pendingConfirmationUrl}
                      onClick={() => setRedirectToShopify(true)}
                      rel="noreferrer"
                    >
                      <Button primary> Approve plan </Button>
                    </a>
                  ) : (
                    <Button primary onClick={() => setRedirectOnApprove(true)}>
                      Approve plan
                    </Button>
                  )}
                </div>
              )}
              {!isPending && !isCurrent && (
                <React.Fragment>
                  <Button disabled={isSwitchingPlan} onClick={onSwitchToPlan}>
                    {trialDays > 0 ? "Start free trial" : hasActivePlan ? "Switch to plan" : "Select plan"}
                  </Button>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
        </LegacyCard>
      </Layout.Section>
    </div>
  );
}

/**
 * Shopify has limit of max 255 characters for return URL. So we want return URL to be as minimal as possible.
 * So here we build URL of current page without unnecessary query string parameters.
 */
export function getReturnUrl(hasActivePlan: boolean) {
  // Redirect to smartrr page, even if we are in iframe
  const returnUrl = new URL(window.location.href);

  // If organization has no active plan, then we return to route admin path - here is what will happen:
  // 1. Router will open default route (without active plan it is Account page) and we will sync updated
  // plans from Shopify
  // 2. Then (if setup is not complete) automatic redirect in Router component will be triggered and it
  // will take us to Setup page
  // 3. Or (if setup was complete) default path in Router will change and it will take us to Subscriptions page
  //
  // If organization has active plan, then we return to account page to sync plans statuses from Shopify
  if (!hasActivePlan) {
    returnUrl.pathname = adminRoutePrefix;
  }

  // Build new query string that includes only shop parameter
  const cleanSearchParams = new URLSearchParams();
  const shop = returnUrl.searchParams.get(shopUrlPropertyName);
  if (shop) {
    cleanSearchParams.set(shopUrlPropertyName, shop);
  }
  returnUrl.search = cleanSearchParams.toString();

  return returnUrl.toString();
}
